import AnimationRevealPage from "helpers/AnimationRevealPage";
import React, { useState } from "react";
import tw from "twin.macro";
import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import { electronics } from "helpers/categoryProducts";
import CategoryNav from "components/category/CategoryNav";

const categoryNav = [
  { id: "bags", name: "Bags", data: ["Backpacks", "Wallets", "Satchels"] },
  { id: "tapes", name: "Tapes", data: ["Tapes 1", "Tapes 2", "Tapes 3"] },
  {
    id: "electronics",
    name: "Electronics",
    data: electronics,
  },
];

const ItemsWrapper = tw.div`mt-8`; // Define the styled component for items

export default () => {
  const [activeTab, setActiveTab] = useState(categoryNav[0].id);

  // Twin-styled components
  const Container = tw.section`py-12 bg-gray-100`;
  const NavWrapper = tw.div`flex flex-wrap justify-start gap-2 max-w-screen-xl mx-auto`;
  const Card = tw.div`flex flex-col items-center text-center px-4 py-2 rounded-lg shadow-lg m-2 transition-transform duration-300 transform hover:scale-105 cursor-pointer`;

  // Dynamic styles for active/hover state
  const activeCardStyles = tw`bg-[#6415ff] text-white`;
  const inactiveCardStyles = tw`bg-white text-[#6415ff]`;

  const CardTitle = tw.h2`text-xl font-bold`;

  return (
    <AnimationRevealPage>
      <Header />
      <Container>
        {/* <NavWrapper>
          {categoryNav.map((category) => (
            <Card
              key={category.id}
              className={
                activeTab === category.id
                  ? activeCardStyles
                  : inactiveCardStyles
              }
              onClick={() => setActiveTab(category.id)}
            >
              <CardTitle>{category.name}</CardTitle>
            </Card>
          ))}
        </NavWrapper> */}

        <CategoryNav />
      </Container>
      <Footer />
    </AnimationRevealPage>
  );
};
