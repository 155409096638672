import React from "react";
import tw from "twin.macro";

const Container = tw.div`flex flex-col items-center justify-center gap-20 py-16 bg-white`;
const Section = tw.div`w-full`; // Generic section wrapper
const Title = tw.h1`my-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center leading-tight`;
const Description = tw.p`text-base sm:text-lg lg:text-xl text-center text-gray-600`;
const CardsWrapper = tw.div`flex flex-col lg:flex-row justify-around w-screen max-w-screen-xl mx-auto`;
const Card = tw.div`flex flex-col items-center text-center p-6 rounded-lg shadow-lg m-4 transition-transform duration-300 hover:cursor-pointer transform hover:scale-105`;
const CardTitle = tw.h2`text-xl font-bold text-[#6415ff] mb-2`;
const CardDescription = tw.p`text-gray-700`;

// Dynamic Profile component accepting props for services and markets
const Profile = ({ services, markets }) => {
  return (
    <Container>
      {/* Services Section */}
      <Section>
        <Title>Services We Offer</Title>
        {/* <Description>We provide the following services:</Description> */}
        <CardsWrapper>
          {services.map((service, index) => (
            <Card key={index}>
              <CardTitle>{service.title}</CardTitle>
              <CardDescription>{service.description}</CardDescription>
            </Card>
          ))}
        </CardsWrapper>
      </Section>

      {/* Markets Section */}
      <Section>
        <Title className="mt-16">Markets We Serve</Title>
        <Description>We serve customers in various industries, including:</Description>
        <CardsWrapper>
          {markets.map((market, index) => (
            <Card key={index}>
              <CardTitle>{market.title}</CardTitle>
              <CardDescription>{market.description}</CardDescription>
            </Card>
          ))}
        </CardsWrapper>
      </Section>
    </Container>
  );
};

export default Profile;
