import React, { useState } from "react";
import { motion } from "framer-motion";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { Container, ContentWithPaddingXl } from "components/misc/Layouts.js";
import { SectionHeading } from "components/misc/Headings.js";
import { ReactComponent as SvgDecoratorBlob1 } from "images/svg-decorator-blob-5.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "images/svg-decorator-blob-7.svg";
import {
  electronics,
  hdpeProducts,
  ldpeProducts,
  lldpeProducts,
  pvcProducts,
  tapeProducts,
} from "helpers/categoryProducts";
import { Link } from "react-router-dom";

const HeaderRow = tw.div`flex justify-center items-center flex-col xl:flex-row`;
const Header = tw(SectionHeading)`my-0`;
const TabsControl = tw.div`flex flex-wrap bg-gray-200 px-2 py-2 rounded leading-none mt-12 xl:mt-0`;

const TabControl = styled.div`
  ${tw`cursor-pointer px-6 py-3 mt-2 sm:mt-0 sm:mr-2 last:mr-0 text-gray-600 font-medium rounded-sm transition duration-300 text-sm sm:text-base w-1/2 sm:w-auto text-center`}
  &:hover {
    ${tw`bg-gray-300 text-gray-700`}
  }
  ${(props) => props.active && tw`bg-primary-500! text-gray-100!`}
  }
`;

const TabContent = tw(
  motion.div
)`mt-6 flex flex-wrap sm:-mr-10 md:-mr-6 lg:-mr-12`;
const CardContainer = tw.div`mt-10 w-full sm:w-1/2 md:w-1/3 lg:w-1/4 md:pr-6 lg:pr-12`;
const Card = tw(
  motion.a
)`p-4 bg-gray-200 rounded-2xl block max-w-xs no-underline mx-auto sm:max-w-none sm:mx-0 transition-transform duration-300 hover:cursor-pointer transform hover:scale-105`;
const CardImageContainer = styled.div`
  ${(props) =>
    css`
      background-image: url("${props.imageSrc}");
    `}
  ${tw`h-56 xl:h-64 bg-center bg-cover relative rounded-2xl`}
`;

const CardButton = tw.a`text-sm border-0`;

const CardText = tw.div`text-gray-900`;
const CardTitle = tw.h5`my-0 my-4 text-lg font-semibold text-center group-hover:text-primary-500`;
const CardContent = tw.p`mt-1 text-sm font-medium text-center no-underline text-gray-600`;

const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none -z-20 absolute right-0 top-0 h-64 w-64 opacity-15 transform translate-x-2/3 -translate-y-12 text-pink-400`}
`;
const DecoratorBlob2 = styled(SvgDecoratorBlob2)`
  ${tw`pointer-events-none -z-20 absolute left-0 bottom-0 h-80 w-80 opacity-15 transform -translate-x-2/3 text-primary-500`}
`;

export default ({
  heading = "Checkout the Menu",
  tabs = {
    Electronics: electronics,
    Tapes: tapeProducts,
    // Bags: getRandomCards(),
    LDPE: ldpeProducts,
    HDPE: hdpeProducts,
    LLDPE: lldpeProducts,
    // PVC: pvcProducts,
    // Wood: getRandomCards(),
  },
}) => {
  /*
   * To customize the tabs, pass in data using the `tabs` prop. It should be an object which contains the name of the tab
   * as the key and value of the key will be its content (as an array of objects).
   * To see what attributes are configurable of each object inside this array see the example above for "Starters".
   */
  const tabsKeys = Object.keys(tabs);
  const [activeTab, setActiveTab] = useState(tabsKeys[0]);

  return (
    <Container tw="mb-20">
      <ContentWithPaddingXl tw="py-0">
        <HeaderRow>
          <TabsControl>
            {Object.keys(tabs).map((tabName, index) => (
              <TabControl
                key={index}
                active={activeTab === tabName}
                onClick={() => setActiveTab(tabName)}
              >
                {tabName}
              </TabControl>
            ))}
          </TabsControl>
        </HeaderRow>

        {tabsKeys.map((tabKey, index) => (
          <TabContent
            key={index}
            variants={{
              current: {
                opacity: 1,
                scale: 1,
                display: "flex",
                justifyContent: "center",
              },
              hidden: {
                opacity: 0,
                scale: 0.8,
                display: "none",
              },
            }}
            transition={{ duration: 0.4 }}
            initial={activeTab === tabKey ? "current" : "hidden"}
            animate={activeTab === tabKey ? "current" : "hidden"}
          >
            {tabs[tabKey].map((card, index) => {
              let route = "";

              console.log("card", card);
              console.log("tabKey", tabKey);
              console.log("route", route);

              // Switch case to determine the relevant route based on tabKey
              switch (tabKey) {
                case "Electronics":
                  route = `/products/electronics/${card.id}`;
                  break;
                case "Tapes":
                  route = `/products/tapes/${card.id}`;
                  break;
                case "Bags":
                  route = `/products/bags/${card.id}`;
                  break;
                case "LDPE":
                  route = `/products/ldpe/${card.id}`;
                  break;
                case "HDPE":
                  route = `/products/hdpe/${card.id}`;
                  break;
                case "LLDPE":
                  route = `/products/lldpe/${card.id}`;
                  break;
                case "PVC":
                  route = `/products/pvc/${card.id}`;
                default:
                  route = `/products/unknown/${card.id}`; // Default route, can be handled for unknown tabs
              }

              return (
                <CardContainer key={index}>
                  <Link to={route}>
                    <Card
                      className="group"
                      // href={card.url}
                      initial="rest"
                      whileHover="hover"
                      animate="rest"
                    >
                      <CardText>
                        <CardImageContainer imageSrc={card.imageSrc} />
                        <CardTitle>{card.name}</CardTitle>
                        {/* <CardContent>{card.description}</CardContent> */}
                      </CardText>
                      <div tw="flex justify-center">
                        <CardButton
                          initial="rest"
                          whileHover="hover"
                          animate="rest"
                          tw="mb-4 bg-primary-500 hover:bg-primary-700 py-1 px-4 rounded-lg text-white text-lg"
                        >
                          View More
                        </CardButton>
                      </div>
                    </Card>
                  </Link>
                </CardContainer>
              );
            })}
          </TabContent>
        ))}
      </ContentWithPaddingXl>
      <DecoratorBlob1 />
      <DecoratorBlob2 />
    </Container>
  );
};

/* This function is only there for demo purposes. It populates placeholder cards */
const getRandomCards = () => {
  const cards = [
    {
      imageSrc:
        "https://images.unsplash.com/photo-1512621776951-a57141f2eefd?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=500&q=80",
      title: "Chicken Chilled",
      content: "Chicken Main Course",
      price: "$5.99",
      rating: "5.0",
      reviews: "87",
      url: "#",
    },
    {
      imageSrc:
        "https://images.unsplash.com/photo-1582254465498-6bc70419b607?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=500&q=80",
      title: "Samsa Beef",
      content: "Fried Mexican Beef",
      price: "$3.99",
      rating: "4.5",
      reviews: "34",
      url: "#",
    },
  ];

  // Shuffle array
  return cards.sort(() => Math.random() - 0.5);
};
