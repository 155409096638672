import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { css } from "styled-components/macro"; // eslint-disable-line

// Styled components using twin.macro
const Container = tw.div`mb-20 flex flex-col items-center justify-center bg-white`;
const Heading = tw.h1`text-4xl font-bold text-gray-900 text-center mb-6`;
const Description = tw.p`text-center text-lg text-gray-600 max-w-xl mb-10 px-4`;
const ButtonContainer = tw.div`flex space-x-4`;
const Button = styled.a`
  ${tw`px-6 py-3 bg-[#6415ff] text-white font-bold rounded-full border-0 shadow-md transition-transform transform hover:scale-105`}
`;

const WelcomeSection = () => {
  return (
    <Container>
      <Heading>WELCOME TO OUR COMPANY</Heading>
      <Description>
        Legacy Lifeline Pvt. Ltd. is a professional plastic raw material, 
        recycled and modified plastic material (PP, LDPE, HDPE, LLDPE, PVC, PET, POM) 
        and other products import and export company. We were founded in 2021, but we 
        started our plastics business in China back in the early 90s, and from that 
        point of view, we have a long history. Since its establishment, the company 
        has taken the plasticization business as the core, meeting customer needs as 
        its own responsibility.
      </Description>
      <ButtonContainer>
        <Button href="./about-us">view more</Button>
        <Button href="/contact-us">contact us</Button>
      </ButtonContainer>
    </Container>
  );
};

export default WelcomeSection;
