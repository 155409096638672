import React from "react";
import { motion } from "framer-motion";
import tw, { styled } from "twin.macro";
import { css } from "styled-components";
import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import img4 from "./../../images/electronics/pgt-1218.png";
import { electronics } from "helpers/categoryProducts";
import { useParams } from "react-router-dom";

// Styled Components
const ProductPageContainer = tw.div`max-w-screen-lg mx-auto my-16 p-8 rounded-lg shadow-lg bg-gradient-to-b from-blue-100 to-white`;
const ProductContainer = tw.div`grid grid-cols-1 md:grid-cols-2 gap-12 items-start`;
const ImageContainer = styled.div`
  ${(props) =>
    css`
      background-image: url("${props.imageSrc}");
    `}
  ${tw`w-full h-96 bg-center bg-cover rounded-lg shadow-xl`}
`;
const InfoContainer = tw.div`space-y-6`;
const ProductTitle = tw.h1`text-5xl font-extrabold text-blue-900 drop-shadow-lg`;
const ProductModel = tw.h2`text-2xl font-semibold text-gray-500 mt-4`;
const ProductSpecifications = tw.ul`list-disc list-inside text-gray-700 text-lg`;
const SpecificationsTitle = tw.h3`text-2xl font-semibold text-blue-700 mt-4 border-b-2 border-blue-300 pb-2`;
const PackingDetails = tw.ul`list-none text-gray-700 text-lg`;
const PackingTitle = tw.h3`text-2xl font-semibold text-blue-700 mt-4 border-b-2 border-blue-300 pb-2`;
const DetailItem = tw.li`py-1`;
const Button = tw(
  motion.button
)`px-8 py-3 rounded-lg bg-gradient-to-r from-purple-500 to-indigo-500 hover:bg-gradient-to-l hover:from-indigo-500 hover:to-purple-500 text-white text-lg mt-6 shadow-lg transition-transform transform hover:scale-105`;

export default function ElectronicsProductPage() {
  const { id } = useParams();
  const product = electronics.filter((item) => item.id === id)[0];

  // Helper function to format keys into human-readable text
  function formatKey(key) {
    const acronymMap = {
      pcsPerCtn: "PCS per Carton",
      giftBoxSize: "Gift Box Size",
      cartonSize: "Carton Size",
      gw: "Gross Weight",
      nw: "Net Weight",
      pcs20GP: "PCS per 20GP",
      pcs40GP: "PCS per 40GP",
      pcs40HQ: "PCS per 40HQ",
    };

    // If the key is in the acronym map, return the mapped value
    if (acronymMap[key]) {
      return acronymMap[key];
    }

    // Otherwise, split camelCase into words and capitalize the first letter of each word
    return key
      .replace(/([a-z0-9])([A-Z])/g, "$1 $2") // Split camel case into words
      .replace(/^./, (str) => str.toUpperCase()); // Capitalize the first letter
  }

  return (
    <>
      <Header />

      {/* Main content wrapped with motion.div for animation */}
      <motion.div
        initial={{ opacity: 0, y: 50 }} // Start invisible and slightly below
        animate={{ opacity: 1, y: 0 }} // Fade in and move to the original position
        transition={{ duration: 0.5, delay: 0.2 }} // Animation delay and duration
      >
        <ProductPageContainer>
          <ProductContainer>
            {/* Product Image */}
            <ImageContainer imageSrc={product.imageSrc} />

            {/* Product Info */}
            <InfoContainer>
              <ProductTitle>{product.name}</ProductTitle>
              <ProductModel>Model: {product.model}</ProductModel>

              {/* Specifications */}
              <SpecificationsTitle>Specifications:</SpecificationsTitle>
              <ProductSpecifications>
                {product.specifications.map((spec, index) => (
                  <DetailItem key={index}>{spec}</DetailItem>
                ))}
              </ProductSpecifications>

              {/* Packing Details */}
              <PackingTitle>Packing Details:</PackingTitle>
              <PackingDetails>
                {Object.entries(product.packingDetails).map(
                  ([key, value], index) => (
                    <DetailItem key={index}>
                      <strong>{formatKey(key)}: </strong> {value || "N/A"}
                    </DetailItem>
                  )
                )}
              </PackingDetails>

              {/* Call to Action Button */}
              <Button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                onClick={() => (window.location.href = "./../../contact-us")}
              >
                Get a Quote
              </Button>
            </InfoContainer>
          </ProductContainer>
        </ProductPageContainer>
      </motion.div>

      <Footer />
    </>
  );
}
