import React from "react";
import { motion } from "framer-motion";
import tw, { styled } from "twin.macro";
import { css } from "styled-components";
import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import { useParams } from "react-router-dom";
import { tapeProducts } from "./../../helpers/categoryProducts"; // Assuming this is where the tapeProducts array is stored

// Styled Components
const ProductPageContainer = tw.div`max-w-screen-lg mx-auto my-16 p-8 rounded-lg shadow-lg bg-gradient-to-b from-blue-100 to-white`;
const ProductContainer = tw.div`grid grid-cols-1 md:grid-cols-2 gap-12 items-start`;
const ImageContainer = styled.div`
  ${(props) =>
    css`
      background-image: url("${props.imageSrc || "path/to/default-image.jpg"}");
    `}
  ${tw`w-full h-96 bg-center bg-cover rounded-lg shadow-xl`}
`;
const InfoContainer = tw.div`space-y-6`;
const ProductTitle = tw.h1`text-5xl font-extrabold text-blue-900 drop-shadow-lg`;
const ProductCode = tw.h2`text-2xl font-semibold text-gray-500 mt-4`;
const ProductSpecifications = tw.ul`list-disc list-inside text-gray-700 text-lg`;
const SpecificationsTitle = tw.h3`text-2xl font-semibold text-blue-700 mt-4 border-b-2 border-blue-300 pb-2`;
const PackingDetails = tw.ul`list-none text-gray-700 text-lg`;
const PackingTitle = tw.h3`text-2xl font-semibold text-blue-700 mt-4 border-b-2 border-blue-300 pb-2`;
const DetailItem = tw.li`py-1`;
const Button = tw(
  motion.button
)`px-8 py-3 rounded-lg bg-gradient-to-r from-purple-500 to-indigo-500 hover:bg-gradient-to-l hover:from-indigo-500 hover:to-purple-500 text-white text-lg mt-6 shadow-lg transition-transform transform hover:scale-105`;

export default function TapesComponent() {
  const { id } = useParams();

  // Find the product by id, and add null check
  const product =
    tapeProducts.find((item) => item.id.toString() === id) || null;

  // If product is null, display an error message
  if (!product) {
    return (
      <>
        <Header />
        <ProductPageContainer>
          <h2>Product not found</h2>
        </ProductPageContainer>
      </>
    );
  }

  return (
    <>
      <Header />
      <motion.div
        initial={{ opacity: 0, y: 50 }} // Start invisible and slightly below
        animate={{ opacity: 1, y: 0 }} // Fade in and move to the original position
        transition={{ duration: 0.5, delay: 0.2 }} // Animation delay and duration
      >
        <ProductPageContainer>
          <ProductContainer>
            {/* Product Image */}
            <ImageContainer
              imageSrc={product.imageSrc || "path/to/default-image.jpg"}
            />

            {/* Product Info */}
            <InfoContainer>
              <ProductTitle>
                {product.name || "No Product Name Available"}
              </ProductTitle>
              <ProductCode>Code: {product.code || "N/A"}</ProductCode>

              {/* Specifications */}
              <SpecificationsTitle>Specifications:</SpecificationsTitle>
              <ProductSpecifications>
                <DetailItem>
                  <strong>Glue:</strong> {product.glue || "N/A"}
                </DetailItem>
                <DetailItem>
                  <strong>Thickness:</strong> {product.thickness || "N/A"}
                </DetailItem>
                <DetailItem>
                  <strong>Tack:</strong> {product.tack || "N/A"}
                </DetailItem>
                <DetailItem>
                  <strong>Peel Adhesive:</strong>{" "}
                  {product.peelAdhesive || "N/A"}
                </DetailItem>
                <DetailItem>
                  <strong>Tensile Strength:</strong>{" "}
                  {product.tensileStrength || "N/A"}
                </DetailItem>
              </ProductSpecifications>

              {/* Main Usage */}
              <PackingTitle>Main Usage:</PackingTitle>
              <PackingDetails>
                <DetailItem>{product.mainUsage || "N/A"}</DetailItem>
              </PackingDetails>

              {/* Call to Action Button */}
              <Button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                onClick={() => (window.location.href = "./../../contact-us")}
              >
                Get a Quote
              </Button>
            </InfoContainer>
          </ProductContainer>
        </ProductPageContainer>
      </motion.div>
      <Footer />
    </>
  );
}
