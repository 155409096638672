import React from "react";
import { motion } from "framer-motion";
import tw, { styled } from "twin.macro";
import { css } from "styled-components";
import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import { useParams } from "react-router-dom";
import { hdpeProducts } from "./../../helpers/categoryProducts"; // Assuming hdpeProducts is imported here

// Styled Components
const ProductPageContainer = tw.div`max-w-screen-lg mx-auto my-16 p-8 rounded-lg shadow-lg bg-gradient-to-b from-blue-100 to-white`;
const ProductContainer = tw.div`grid grid-cols-1 md:grid-cols-2 gap-12 items-start`;
const ImageContainer = styled.div`
  ${(props) =>
    css`
      background-image: url("${props.imageSrc || "path/to/default-image.jpg"}");
    `}
  ${tw`w-full h-96 bg-center bg-cover rounded-lg shadow-xl`}
`;
const InfoContainer = tw.div`space-y-6`;
const ProductTitle = tw.h1`text-5xl font-extrabold text-blue-900 drop-shadow-lg`;
const ProductMFR = tw.h2`text-2xl font-semibold text-gray-500 mt-4`;
const ProductSpecifications = tw.ul`list-disc list-inside text-gray-700 text-lg`;
const SpecificationsTitle = tw.h3`text-2xl font-semibold text-blue-700 mt-4 border-b-2 border-blue-300 pb-2`;
const DetailItem = tw.li`py-1`;
const Button = tw(
  motion.button
)`px-8 py-3 rounded-lg bg-gradient-to-r from-purple-500 to-indigo-500 hover:bg-gradient-to-l hover:from-indigo-500 hover:to-purple-500 text-white text-lg mt-6 shadow-lg transition-transform transform hover:scale-105`;

export default function HDPEProductPage() {
  const { id } = useParams();

  // Find the product by id, using parseInt to convert id to number
  const product = hdpeProducts.find((item) => item.id === parseInt(id)) || null;

  // If product is not found
  if (!product) {
    return (
      <>
        <Header />
        <ProductPageContainer>
          <h2>Product not found</h2>
        </ProductPageContainer>
      </>
    );
  }

  return (
    <>
      <Header />

      {/* Main content wrapped with motion.div to add delay */}
      <motion.div
        initial={{ opacity: 0, y: 50 }} // Start invisible and move up
        animate={{ opacity: 1, y: 0 }} // Fade in and move to original position
        transition={{ duration: 0.5, delay: 0.2 }} // Animation duration and delay
      >
        <ProductPageContainer>
          <ProductContainer>
            {/* Product Image */}
            <ImageContainer
              imageSrc={product.imageSrc || "path/to/default-image.jpg"}
            />

            {/* Product Info */}
            <InfoContainer>
              <ProductTitle>
                {product.name || "No Product Name Available"}
              </ProductTitle>
              <ProductMFR>MFR: {product.mfr || "N/A"}</ProductMFR>

              {/* Specifications */}
              <SpecificationsTitle>Material Type:</SpecificationsTitle>
              <ProductSpecifications>
                <DetailItem>
                  <strong>Material Type:</strong>{" "}
                  {product.materialType || "N/A"}
                </DetailItem>
                <DetailItem>
                  <strong>Applications:</strong>{" "}
                  {product.applications?.length > 0
                    ? product.applications.join(", ")
                    : "N/A"}
                </DetailItem>
                <DetailItem>
                  <strong>Material Properties:</strong>{" "}
                  {product.properties
                    ? Object.keys(product.properties)
                        .map((key) => `${key}: ${product.properties[key]}`)
                        .join(", ")
                    : "N/A"}
                </DetailItem>
              </ProductSpecifications>

              {/* Technical Specifications */}
              <SpecificationsTitle>
                Technical Specifications:
              </SpecificationsTitle>
              <ProductSpecifications>
                <DetailItem>
                  <strong>MFI:</strong>{" "}
                  {product.technicalSpecifications?.mfi || "N/A"}
                </DetailItem>
                <DetailItem>
                  <strong>Other Specifications:</strong>{" "}
                  {product.technicalSpecifications?.other?.length > 0
                    ? product.technicalSpecifications.other.join(", ")
                    : "N/A"}
                </DetailItem>
              </ProductSpecifications>

              {/* Safety and Environmental Considerations */}
              <SpecificationsTitle>
                Safety and Environmental Considerations:
              </SpecificationsTitle>
              <ProductSpecifications>
                <DetailItem>
                  {product.safetyAndEnvironmentalConsiderations
                    ? product.safetyAndEnvironmentalConsiderations.join(", ")
                    : "N/A"}
                </DetailItem>
              </ProductSpecifications>

              {/* Storage and Handling */}
              {product.storageAndHandling && (
                <>
                  <SpecificationsTitle>
                    Storage and Handling:
                  </SpecificationsTitle>
                  <ProductSpecifications>
                    <DetailItem>
                      <strong>Storage Conditions:</strong>{" "}
                      {product.storageAndHandling.storageConditions || "N/A"}
                    </DetailItem>
                    <DetailItem>
                      <strong>Process Within:</strong>{" "}
                      {product.storageAndHandling.processWithin || "N/A"}
                    </DetailItem>
                    <DetailItem>
                      <strong>Ideal Storage:</strong>{" "}
                      {product.storageAndHandling.idealStorage || "N/A"}
                    </DetailItem>
                  </ProductSpecifications>
                </>
              )}

              {/* Call to Action Button */}
              <Button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                onClick={() => (window.location.href = "./../../contact-us")}
              >
                Get a Quote
              </Button>
            </InfoContainer>
          </ProductContainer>
        </ProductPageContainer>
      </motion.div>
      <Footer />
    </>
  );
}
